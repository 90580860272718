import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import ContainerHero from '../components/ContainerHero'
import ContentSelfcheck from '../components/ContentSelfcheck'
import SEO from '../components/SEO'

const Selfcheck = () => {
  return (
    <Layout>
      <SEO
        title="IT Self-Check"
        description="Haben Sie eine Übersicht über die Sicherheit Ihrer IT-Infrastruktur?
Finden Sie in unserem IT-Check heraus, ob Ihre IT Ihren Bedürfnissen genügt."
      />
      <Container>
        <ContainerHero newsletter>
          <ContentSelfcheck>
            <PageTitle>IT-Selfcheck</PageTitle>
          </ContentSelfcheck>
        </ContainerHero>
      </Container>
    </Layout>
  )
}

export default Selfcheck
